@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
@font-face {
  font-family: "NanumPen";
  font-weight: 200;
  src: url("./fonts/AdihausDIN-Cond.ttf") format("truetype");
}

body {
  font-family: "NanumPen";
  background-color: black;
}
